import React from 'react';
import MainLayout from 'layouts/mainLayout';
import InvitePage from 'components/Pages/invite';
import CircularProgressOverlay from 'components/CircularProgressOverlay';

const InviteTemplate = (props) => (
  <MainLayout>
    <CircularProgressOverlay>
      <InvitePage {...props} />
    </CircularProgressOverlay>
  </MainLayout>
);

export default InviteTemplate;
