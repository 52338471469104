import React, { useState, useEffect, useContext } from 'react';
import queryString from 'query-string';
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { navigate } from 'gatsby';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import DoneTwoToneIcon from '@material-ui/icons/DoneTwoTone';
import SentimentDissatisfiedTwoToneIcon from '@material-ui/icons/SentimentDissatisfiedTwoTone';

import { notNull, isNull } from 'utils/text';

import { CircularProgressContext } from 'components/CircularProgressOverlay';
import { loginService, setLoginUser } from '../../services/loginService';
import { SettingsEthernetSharp } from '@material-ui/icons';

const awsConfig = {
  Auth: {
    region: process.env.AWS_CONFIG_PORTAL_REGION,
    userPoolId: process.env.AWS_CONFIG_PORTAL_USER_POOL_ID,
    userPoolWebClientId: process.env.AWS_CONFIG_PORTAL_USER_POOL_WEBCLIENT_ID,
    identityPoolId: process.env.AWS_CONFIG_PORTAL_IDENTITY_POOL_ID,
  },
};

Amplify.configure(awsConfig);

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        Pergo
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/featured/?car,taxi)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
  },
  avatar1: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.error.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

export default function InvitePage(props) {
  const classes = useStyles();
  const [status, setStatus] = useState(0);
  const [formValues, setFormValues] = useState({
    email: '',
    code: '',
    password: '',
    cpassword: '',
  });
  const [formErrors, setFormErrors] = useState(null);
  const { showCircularProgress, hideCircularProgress } = useContext(CircularProgressContext);

  const handleResendVerify = async (email = false) => {
    showCircularProgress('Please wait...');
    try {
      await Auth.resendSignUp(email || formValues.email);
      console.log('AWS CODE RESEND SUCCESS');
      hideCircularProgress();
    } catch (err) {
      hideCircularProgress();
      console.log('error resending code', err);
    }
  }

  const handleChange = (id, value) => {
    setFormValues({
      ...formValues,
      [id]: value
    });
  }

  useEffect(() => {
    async function invitationCheck(token) {
      showCircularProgress('Please wait');
      const data = await loginService.admin_invite_check(token);
      hideCircularProgress();
      if (data) {
        const email = data?.data?.data?.email;
        if (data.data.status === 'SUCCESS' && email) {
          handleChange('email', email);
          showCircularProgress('Please wait...');
          try {
            const user = await Auth.signUp({
              username: email,
              password: process.env.AWS_CONFIG_USER_NORMAL_PASSWORD,
              attributes: { email, given_name: '', family_name: '', phone_number: '' },
            });
            console.log('AWS SIGNUP SUCCESS : ', user);
            hideCircularProgress();
            setStatus(1);
          } catch (err) {
            console.log('AWS SIGNUP ERROR : ', err);
            if (err.code === 'UsernameExistsException') {
              try {
                const signedUser = await Auth.signIn(email, process.env.AWS_CONFIG_USER_NORMAL_PASSWORD);
                hideCircularProgress();
                if (signedUser) {
                  setStatus(2);
                }
              } catch (error) {
                console.log(error);
                hideCircularProgress();
                if (error.code === 'UserNotConfirmedException') {
                  await handleResendVerify(email);
                  setStatus(1);
                } else {
                  setStatus(-2);
                }
              }
            } else {
              hideCircularProgress();
              setStatus(-2);
            }
          }
        } else if (data?.data?.data?.code) {
          setStatus(data?.data?.data?.code == 'OLD_LINK' ? -1 : -2);
        }
      }
    }
    
    const parsed = queryString.parse(props.location.search);

    if (parsed?.token) {
      invitationCheck(parsed?.token);
    }
  }, []);

  const checkValueValidate = (id, value) => {
    let valid = notNull(value) && value.length > 0;
    let error = null;

    if (!valid) {
      error = 'Please input value';
    } else if (id === 'password') {
      valid = value.length >= 6;
      error = valid ? null : 'Please input strong password';
    } else if (id === 'cpassword') {
      valid = formValues.password === formValues.cpassword;
      error = valid ? null : 'Please input correctly';
    }

    return error;
  };

  const checkStepValidate = (keys) => {
    const errors = {};
    keys.forEach(id => {
      const error = checkValueValidate(id, notNull(formValues) ? formValues[id] : null);
      errors[id] = error;
    })
    setFormErrors(errors);

    const stepValid = Object.keys(errors).filter(item => errors[item] !== null).length;
    return stepValid === 0;
  };

  const handleVerify = async () => {
    const keys = ['verification_code'];
    if (!checkStepValidate(keys)) {
      return;
    }

    showCircularProgress('Verifying...');
    try {
      await Auth.confirmSignUp(formValues.email, formValues.verification_code);
      console.log('AWS CODE VERIFY SUCCESS');
      hideCircularProgress();
      setStatus(2);
    } catch (err) {
      hideCircularProgress();
      console.log('error confirming sign up', err);
      setFormErrors({
        verification_code: err.message
      });
    }
  }

  const handleSetPassword = async () => {
    const keys = ['password', 'cpassword'];
    if (!checkStepValidate(keys)) {
      return;
    }
    
    showCircularProgress('Please wait');
    let signedUser = null;
    try {
      signedUser = await Auth.signIn(formValues.email, process.env.AWS_CONFIG_USER_NORMAL_PASSWORD);
    } catch (error) {
      console.log(error);
    }

    if (!signedUser) {
      setStatus(-2);
      return;
    }

    try {
      const result = await Auth.changePassword(signedUser, process.env.AWS_CONFIG_USER_NORMAL_PASSWORD, formValues.password);
      loginService.update_password(formValues.email, formValues.password, 1).then(({ data }) => {
        if (data.status === 'SUCCESS') {
          loginService.login(formValues.email, formValues.password).then(({ data }) => {
            hideCircularProgress();
            if (data.status === 'SUCCESS' && data.data.num_rows > 0) {
              setLoginUser(data.data.data);
              navigate('/');
            }
          });
        } else {
          hideCircularProgress();
        }
      });
    } catch (error) {
      console.log(error);
      hideCircularProgress();
      if (error.code === 'InvalidPasswordException') {
        setFormErrors({
          password: error.message,
        });
      } else {
        setStatus(-2);
      }
    }
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          {status == 0 && 
            <Typography component="h1" variant="h5" className="mt-3 text-center">
              Please wait...
            </Typography>
          }

          {status == -1 && (
            <>
              <Avatar className={classes.avatar1}>
                <SentimentDissatisfiedTwoToneIcon />
              </Avatar>
              <Typography component="h1" variant="h5" className="mt-3 text-center">
                Sorry, Your invitation link is expired.<br/>Please contact Portal manager again.
              </Typography>
            </>
          )}

          {status == -2 && (
            <>
              <Avatar className={classes.avatar1}>
                <SentimentDissatisfiedTwoToneIcon />
              </Avatar>
              <Typography component="h1" variant="h5" className="mt-3 text-center">
                Sorry, Something's wrong.
              </Typography>
            </>
          )}

          {status >= 1 && (
            <>
              <Avatar className={classes.avatar}>
                <DoneTwoToneIcon />
              </Avatar>
              <Typography component="h1" variant="h5" className="mb-3">
                Thanks for accepting the invitation
              </Typography>
              <div className={classes.form}>
                <div className="mt-4">
                  {status == 1 && (
                    <>
                      <Typography component="h5" className="mt-4" >
                        Please check your email and input code
                      </Typography>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Verification Code"
                        value={formValues.verification_code}
                        onChange={e => handleChange('verification_code', e.target.value)}
                        error={notNull(formErrors?.verification_code)}
                        helperText={formErrors?.verification_code}
                      />

                      <div className="d-flex">
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          className="my-2 mr-2 py-3"
                          onClick={handleVerify}
                        >
                          Verify
                        </Button>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          className="my-2 ml-2 py-3"
                          onClick={() => handleResendVerify()}
                        >
                          Resend Code
                        </Button>

                      </div>
                    </>
                  )}

                  {status == 2 && (
                    <>
                      <Typography component="h5" className="mt-4" >
                        Please input your password
                      </Typography>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="New Password"
                        type="password"
                        autoComplete="new-password"
                        value={formValues.password}
                        onChange={e => handleChange('password', e.target.value)}
                        error={notNull(formErrors?.password)}
                        helperText={formErrors?.password}
                      />

                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Confirm Password"
                        type="password"
                        autoComplete="new-password"
                        value={formValues.cpassword}
                        onChange={e => handleChange('cpassword', e.target.value)}
                        error={notNull(formErrors?.cpassword)}
                        helperText={formErrors?.cpassword}
                      />

                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className="my-2 py-3"
                        onClick={handleSetPassword}
                      >
                        Set Password
                      </Button>
                    </>
                  )}
                </div>
                <Box mt={5}>
                  <Copyright />
                </Box>
              </div>
            </>
          )}
        </div>
      </Grid>
    </Grid>
  );
}
